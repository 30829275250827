import { VoucherTypes, getVoucherTypeKey } from './voucherTypes';

const lunchType = getVoucherTypeKey(VoucherTypes.Lunch);
const recreationalType = getVoucherTypeKey(VoucherTypes.Recreational);

export const voucherOrderUrlPrefix = 'voucher-order';
const voucherReorderUrlPrefix = 'voucher-reorder';
export const cardOrderUrlPrefix = 'order';
const loadOrderUrlPrefix = 'load-account';
export const createAccountPath = '/create-account';
export const existingAccountPath = '/existing-account';
const signUp = '/sign-up';

const voucherOrderLunch = `/${voucherOrderUrlPrefix}/${VoucherTypes.Lunch}/`;
const voucherOrderRecreational = `/${voucherOrderUrlPrefix}/${VoucherTypes.Recreational}/`;

export const routerPaths = {
  login: '/login',
  signUp,
  signUpCreateAccount: `${signUp}${createAccountPath}`,
  signUpExistingAccount: `${signUp}${existingAccountPath}`,
  home: '/',
  notFound: '/not-found',
  profile: '/profile',
  maksuturvaPayment: '/maksuturva-payment',
  orderDetails: '/order-details/:voucherOrderId',
  orderHistory: '/voucher-order-history',
  reports: '/reports',
  reportsTransactions: '/reports/transactions',
  reportsReports: '/reports/reports',
  reportsTopups: '/reports/topups',
  transactionsHistory: '/transactions-history',
  companyInformation: '/company-information',
  voucherOrderEditDelivery: `/${voucherOrderUrlPrefix}/:voucherType/edit-delivery`,
  voucherOrderEditCompany: `/${voucherOrderUrlPrefix}/:voucherType/edit-company`,
  voucherReorder: `/${voucherReorderUrlPrefix}/:voucherType/:orderId`,
  voucherReorderEditAmount: `/${voucherReorderUrlPrefix}/:voucherType/:orderId/edit-amount`,
  voucherReorderEditDelivery: `/${voucherReorderUrlPrefix}/:voucherType/:orderId/edit-delivery`,
  voucherReorderEditCompany: `/${voucherReorderUrlPrefix}/:voucherType/:orderId/edit-company`,
  voucherOrderPayment: '/voucher-order-payment/:paymentId/:showWarning?',
  cardOrderPayment: '/card-order-payment/:paymentId/:showWarning?',
  orderPaymentStatus: '/order-payment-status/:paymentId',
  resetPassword: '/reset-password',
  resetPasswordRequested: '/reset-password/requested',
  beneficiaries: '/beneficiaries',
  manageBenefits: '/manage-benefits',
  manageBenefitGroup: '/manage-benefits/:benefitGroup',
  newPassword: '/new-password',
  downloadReports: '/download-reports',
  help: '/help',
  maintenance: '/maintenance',
  voucherOrderLunch,
  voucherOrderRecreational,
  voucherOrder: {
    [lunchType]: [
      voucherOrderLunch,
      `/${voucherOrderUrlPrefix}/${VoucherTypes.Lunch}/information`,
      `/${voucherOrderUrlPrefix}/${VoucherTypes.Lunch}/payment`,
      `/${voucherOrderUrlPrefix}/${VoucherTypes.Lunch}/status/:paymentId`
    ],
    [recreationalType]: [
      voucherOrderRecreational,
      `/${voucherOrderUrlPrefix}/${VoucherTypes.Recreational}/information`,
      `/${voucherOrderUrlPrefix}/${VoucherTypes.Recreational}/payment`,
      `/${voucherOrderUrlPrefix}/${VoucherTypes.Recreational}/status/:paymentId`
    ]
  },
  contract: '/contract',
  contractSummary: '/contract/summary',
  resendMail: '/resend',
  resendMailSummary: '/resend-summary',
  resendMailCompleted: '/resend-completed',
  resendMailInvalid: '/resend-invalid',
  resendMailContactDeleted: '/resend-deleted',
  createAccount: '/create-account',
  cardOrderEditCompany: `/${cardOrderUrlPrefix}/edit-company`,
  cardOrder: [
    `/${cardOrderUrlPrefix}/benefits`,
    `/${cardOrderUrlPrefix}/company-information`,
    `/${cardOrderUrlPrefix}/employee-information`,
    `/${cardOrderUrlPrefix}/benefit-usage`,
    `/${cardOrderUrlPrefix}/payment`
  ],
  cardOrderStatus: `/${cardOrderUrlPrefix}/status/:paymentId`,
  loadCompanyAccount: `/${loadOrderUrlPrefix}`,
  requestedLoads: `/${loadOrderUrlPrefix}/requested-loads`,
  loadCompanyAccountStatus: `/${loadOrderUrlPrefix}/status/:paymentId`,
  topupDetails: '/reports/topups/:reportId',
  confirmEmail: '/confirm-email',
  changeEmail: '/change-email'
};

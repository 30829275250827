import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TabName } from '@epi-constants/downloadReports';
import { goToPage } from '@epi-actions/navigation';
import {
  REPORTS_QUERY_NAME,
  REPORTS_SEARCH_NAME
} from '@epi-constants/externalReport';

import { LunchDeduction } from '../components';
import ReportsTab from '../components/ReportsTab/ReportsTab';

export const useSetTabs = () => {
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();

  const handleURLChange = tabName =>
    dispatch(goToPage(pathname + REPORTS_QUERY_NAME + tabName));

  const handleOnTabChange = tabName => {
    handleURLChange(tabName);
  };

  const urlParams = new URLSearchParams(search);
  const isPayrollTabActive =
    TabName[urlParams.get(REPORTS_SEARCH_NAME) ?? 'Lunch'] === TabName.Lunch;

  return {
    tabs: [
      {
        name: TabName.Lunch,
        label: t('download_reports.lunch_tab_name'),
        id: 'tab-lunch-deduction',
        body: <LunchDeduction />
      },
      {
        name: TabName.Other,
        label: t('download_reports.other_tab_name'),
        id: 'tab-other-reports',
        body: <ReportsTab />
      }
    ],
    handleOnTabChange,
    isPayrollTabActive
  };
};

import React from 'react';
import { useTheme } from 'styled-components';
import {
  Box,
  TitleText,
  RadioGroup,
  FormControlLabel,
  Radio
} from 'edenred-ui';
import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFields } from '@epi-forms/helpers';
import { Download } from '@epi-constants/downloadReports';

const name = FormFields.DownloadAs;

export const DownloadAs = () => {
  const { sizeMedium, fontSemiBold, gray85 } = useTheme();
  const { control } = useFormContext();
  const { t } = useTranslation();

  const {
    field: { value, onChange }
  } = useController({
    name,
    control
  });

  return (
    <Box display="flex" alignItems="center" gap={6}>
      <TitleText
        fontSize={sizeMedium}
        fontWeight={fontSemiBold}
        color={gray85}
        noMargin
      >
        {t('download_reports.lunch_deduction.download_as_title')}
      </TitleText>
      <Box fontSize={16} fontWeight={400} position="relative" top={3}>
        <RadioGroup onChange={onChange} value={value}>
          <Box display="flex" gap={6}>
            <FormControlLabel
              value={Download.CSV}
              control={<Radio />}
              label={Download.CSV.toUpperCase()}
              id="choose-as-csv-radio"
            />
            <FormControlLabel
              value={Download.XLS}
              control={<Radio />}
              label={Download.XLS.toUpperCase()}
              id="choose-as-xls-radio"
            />
          </Box>
        </RadioGroup>
      </Box>
    </Box>
  );
};

import { dateRangeModes } from '@epi-constants/downloadReports';
import {
  getFirstDayOfCurrentMonth,
  getFirstDayOfLastMonth,
  getLastDayOfCurrentMonth,
  getLastDayOfLastMonth
} from './dateHelper';

export const setStartDate = (
  dateRangeMode: string,
  startDateValue: string | null = null
) => {
  if (dateRangeMode === dateRangeModes.lastMonth) {
    return getFirstDayOfLastMonth();
  }
  if (dateRangeMode === dateRangeModes.datePicker) {
    return startDateValue;
  }
  return getFirstDayOfCurrentMonth();
};

export const setEndDate = (dateRangeMode, endDateValue = null) => {
  if (dateRangeMode === dateRangeModes.lastMonth) {
    return getLastDayOfLastMonth();
  }

  if (dateRangeMode === dateRangeModes.datePicker) {
    return endDateValue;
  }
  return getLastDayOfCurrentMonth();
};

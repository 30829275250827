import { useEffect, useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IPillData } from '@epi-models/pages/DownloadReports';
import { WAGE_TYPE, setPillsData } from '@epi-constants/downloadReports';
import { FormFields } from '@epi-forms/helpers';

const name = FormFields.Columns;

export const useColumnSection = (pillsData: IPillData[]) => {
  const [pills, setPills] = useState(pillsData);
  const { t } = useTranslation();
  const { control } = useFormContext();

  const wageType = pills.find(item => item.type === WAGE_TYPE);

  const {
    field: { onChange }
  } = useController({
    name,
    control
  });

  const togglePill = (id: number) => {
    const updatedPills = pills.map(item =>
      item.id === id ? { ...item, isActive: !item.isActive } : item
    );
    setPills(updatedPills);
    onChange(updatedPills);
  };

  useEffect(() => {
    const pillsWithNewLang = setPillsData(t);
    const updatedPills = pills.map(item => ({
      ...item,
      name: pillsWithNewLang[item.id].name
    }));
    setPills(updatedPills);
  }, [t]);

  return { pills, togglePill, wageType };
};

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFields } from '@epi-forms/helpers';
import { DATE } from '@epi-constants/downloadReports';

export const useTimePeriodDialog = closeAction => {
  const { t } = useTranslation();
  const { setValue, setError, clearErrors } = useFormContext();

  const [from, setFrom] = useState<null | Date>(null);
  const [to, setTo] = useState<null | Date>(null);

  const handleSave = () => {
    clearErrors(FormFields.StartDate);
    if (from && DATE.getTime() > from.getTime()) {
      setError(FormFields.StartDate, {
        type: 'custom',
        message: t('download_reports.lunch_deduction.time_period.error_message')
      });
    }
    closeAction();
  };

  const handleCancel = () => {
    setFrom(null);
    setTo(null);
    closeAction();
  };

  useEffect(() => {
    if (from && to) {
      setValue(FormFields.StartDate, from);
      setValue(FormFields.EndDate, to);
    }
  }, [from, to]);

  return {
    from,
    to,
    setFrom,
    setTo,
    handleCancel,
    handleSave
  };
};

import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Benefit,
  setPillsData,
  Download,
  dateRangeModes,
  reportDownloadSuccessMessage,
  genericErrorMessage
} from '@epi-constants/downloadReports';
import { FormFields } from '@epi-forms/helpers';
import { setEndDate, setStartDate } from '@epi-helpers/reportsHelper';
import { arrayToObject } from '@epi-helpers/ArrayHelper';
import { fetchPost } from '@epi-helpers/FetchHelpers';
import { deductionReportsApiUrl } from '@epi-repositories/DownloadReportsRepository';
import { showNotification } from '@epi-actions/overlays';

import { downloadReport } from '../components/ReportsTab/ReportsTabHelpers';

export const useDownloadReports = () => {
  const [isPosting, setIsPosting] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Benefit]: Benefit.Lounari,
      [FormFields.Columns]: setPillsData(t),
      [FormFields.DownloadAs]: Download.CSV,
      [FormFields.EndDate]: setEndDate(dateRangeModes.lastMonth),
      [FormFields.StartDate]: setStartDate(dateRangeModes.lastMonth),
      [FormFields.SelectedID]: dateRangeModes.lastMonth,
      [FormFields.WageType]: ''
    }
  });

  const {
    watch,
    formState: { errors }
  } = form;

  const checkForEmptyFields = () => {
    const columns = watch(FormFields.Columns);
    if (columns && typeof columns !== 'string') {
      return columns.find(column => column.isActive);
    }
  };

  const disableSubmit =
    !watch(FormFields.StartDate) ||
    !watch(FormFields.EndDate) ||
    !isEmpty(errors) ||
    !checkForEmptyFields() ||
    isPosting;

  const onSubmit = () => {
    const values = form.getValues();
    if (values?.columns && typeof values.columns !== 'string') {
      setIsPosting(true);
      const columns = arrayToObject(values.columns, 'type');
      if (
        columns.wageType !== 'false' &&
        typeof values.wageType === 'string' &&
        values.wageType.length > 0
      ) {
        columns.wageType = values.wageType;
        delete values.wageType;
      } else {
        columns.wageType = 'false';
        delete values.wageType;
      }

      const dataToSend = {
        ...values,
        loadStatuses: ['Fulfilled'],
        columns
      };

      const onSuccess = data => {
        dispatch(
          showNotification({ message: reportDownloadSuccessMessage }, 'success')
        );
        downloadReport(dataToSend, data, true);
        setIsPosting(false);
      };
      const onFailure = () => {
        dispatch(showNotification({ message: genericErrorMessage }, 'error'));
        setIsPosting(false);
      };

      fetchPost(deductionReportsApiUrl, dataToSend, onSuccess, onFailure);
    } else {
      dispatch(showNotification({ message: genericErrorMessage }, 'error'));
    }
  };

  const footer = {
    submitText: t('download_reports.lunch_deduction.download_button'),
    onSubmit,
    disableSubmit,
    submitButtonWidth: '200px'
  };

  return { form, footer };
};

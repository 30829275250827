import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { showNotification } from '@epi-actions/overlays';
import { fetchGet, fetchPost } from '@epi-helpers/FetchHelpers';
import storage from '@epi-helpers/StorageHelper';
import { companyBenefitGroupSettingsActiveBenefitsUrl } from '@epi-repositories/BenefitGroupSettingsRepository';
import { externalReportsApiUrl } from '@epi-repositories/ExternalReports';

import { DataScopeSection } from '../DataScopeSection';
import { DataColumnsSection } from '../DataColumnsSection';
import { DateRangeSection } from '../DateRangeSection';
import { DownloadAsSection } from '../DownloadAsSection';
import { ReportsTabContext } from '../ReportsTabContext';
import { InfoBar } from '../InfoBar';

import {
  formatReportRequestPayload,
  downloadReport
} from './ReportsTabHelpers';

export const ReportsTabStyles = styled.div`
  padding-bottom: 80px;
  .actions-pane {
    height: 5rem;
    width: 100%;
    background-color: pink;
  }
`;

const genericErrorMessage = 'messages.error';
const reportDownloadSuccessMessage = 'containers.reports.download_success';

const ReportsTab = ({ showError, showSuccess }) => {
  const { t } = useTranslation();
  const { reportsTabState, reportsTabActions } = useContext(ReportsTabContext);
  const saveRequestedColumnsToStorage = () => {
    reportsTabState.columns.map(c => {
      storage.set(`report${c.id}`, c.active);
      return null;
    });
  };

  useEffect(() => {
    const onSuccess = res => {
      reportsTabActions.reportsInitialDataLoaded(res);
    };
    const onFailure = () => showError(genericErrorMessage);
    fetchGet(
      companyBenefitGroupSettingsActiveBenefitsUrl(),
      {},
      onSuccess,
      onFailure
    );
  }, []);

  useEffect(() => {
    if (!reportsTabState.requestDownloadReport) {
      return;
    }
    saveRequestedColumnsToStorage();
    const onSuccess = reportData => {
      downloadReport(reportsTabState, reportData);
      reportsTabActions.reportDownloaded(reportData);
      showSuccess(reportDownloadSuccessMessage);
    };
    const onFailure = () => {
      showError(genericErrorMessage);
      reportsTabActions.reportDownloadFailed();
    };
    fetchPost(
      externalReportsApiUrl,
      formatReportRequestPayload(reportsTabState),
      onSuccess,
      onFailure
    );
  }, [reportsTabState.requestDownloadReport, reportsTabState.downloadAs]);

  const displayColumnPills =
    reportsTabState.dataScope.employeeData ||
    reportsTabState.dataScope.benefits ||
    reportsTabState.dataScope.loads;

  return (
    <ReportsTabStyles>
      <InfoBar text={t('containers.reports.info_bar')} />
      <DataScopeSection dataScope={reportsTabState.dataScope} />
      {displayColumnPills && (
        <DataColumnsSection
          dataScope={reportsTabState.dataScope}
          dataColumnPills={reportsTabState.columns}
          activeBenefits={reportsTabState.activeBenefits}
        />
      )}
      <DateRangeSection dateRangeMode={reportsTabState.dateRangeMode} />
      <DownloadAsSection downloadAs={reportsTabState.downloadAs} />
    </ReportsTabStyles>
  );
};

ReportsTab.propTypes = {
  match: PropTypes.object.isRequired,
  showError: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired
};

export default connect(null, dispatch => ({
  showError: message => {
    dispatch(showNotification({ message }, 'error'));
  },
  showSuccess: message => {
    dispatch(showNotification({ message }, 'success'));
  }
}))(withRouter(ReportsTab));

import React from 'react';
import i18n from 'i18next';
import {
  HomeIcon,
  BenefitsIcon,
  EmployeesIcon,
  TransferIcon,
  ChartIcon,
  VoucherIcon,
  CompanyIcon,
  SupportIcon,
  ReportIcon
} from 'edenred-ui';
import { SystemRoles } from '@epi-constants/roles';
import { routerPaths } from '@epi-constants/routerPaths';
import config from '@epi-config';

export const isSignatory = ({ roles }) => roles.includes(SystemRoles.Signatory);

export const isVoucherManager = ({ roles }) =>
  roles.includes(SystemRoles.VoucherManager);

const isSignatoryWithOrders = ({ roles, hasOrders }) =>
  isSignatory({ roles }) && (config.enableNewContracts || hasOrders);

const menuItems = [
  {
    canDisplay: isSignatory,
    createItem: ({ urlWithLocale }) => ({
      name: i18n.t('navbar.home'),
      link: urlWithLocale(routerPaths.home),
      icon: <HomeIcon />,
      id: 'navbar-home',
      exact: true
    })
  },
  {
    canDisplay: isSignatoryWithOrders,
    createItem: ({ urlWithLocale }) => ({
      name: i18n.t('navbar.manage_benefits'),
      link: urlWithLocale(routerPaths.manageBenefits),
      icon: <BenefitsIcon />,
      id: 'navbar-manage-benefits'
    })
  },
  {
    canDisplay: isSignatoryWithOrders,
    createItem: ({ urlWithLocale }) => ({
      name: i18n.t('navbar.beneficiaries'),
      link: urlWithLocale(routerPaths.beneficiaries),
      icon: <EmployeesIcon />,
      id: 'navbar-beneficiaries'
    })
  },
  {
    canDisplay: isSignatoryWithOrders,
    createItem: ({ urlWithLocale }) => ({
      name: i18n.t('navbar.deposit_money'),
      link: urlWithLocale(routerPaths.loadCompanyAccount),
      icon: <TransferIcon />,
      id: 'navbar-deposit-money'
    })
  },
  {
    canDisplay: isSignatoryWithOrders,
    createItem: ({ urlWithLocale }) => ({
      name: i18n.t('navbar.reports'),
      link: urlWithLocale(routerPaths.reports),
      icon: <ChartIcon />,
      id: 'navbar-reports'
    })
  },
  {
    canDisplay: isSignatoryWithOrders,
    createItem: ({ urlWithLocale }) => ({
      name: i18n.t('navbar.download_reports'),
      link: urlWithLocale(routerPaths.downloadReports),
      icon: <ReportIcon />,
      id: 'navbar-download-reports'
    })
  },
  {
    canDisplay: ({ roles, showVoucherOrder }) =>
      isVoucherManager({ roles }) && showVoucherOrder,
    createItem: ({ urlWithLocale }) => ({
      name: i18n.t('navbar.voucher_order_history'),
      link: urlWithLocale(routerPaths.orderHistory),
      icon: <VoucherIcon />,
      id: 'navbar-voucher-order-history'
    })
  },
  {
    canDisplay: isVoucherManager,
    createItem: ({ urlWithLocale }) => ({
      name: i18n.t('navbar.company_information'),
      link: urlWithLocale(routerPaths.companyInformation),
      icon: <CompanyIcon />,
      id: 'navbar-company-info'
    })
  },
  {
    canDisplay: () => true,
    createItem: ({ urlWithLocale }) => ({
      name: i18n.t('navbar.help'),
      link: urlWithLocale(routerPaths.help),
      icon: <SupportIcon />,
      id: 'navbar-help'
    })
  }
];

export function createMenuItems({
  roles,
  hasOrders,
  urlWithLocale,
  showVoucherOrder
}) {
  return menuItems
    .filter(menuItem =>
      menuItem.canDisplay({ roles, hasOrders, showVoucherOrder })
    )
    .map(menuItem => menuItem.createItem({ urlWithLocale }));
}

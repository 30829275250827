import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ISelectOption } from 'edenred-ui';
import { useSelector } from 'react-redux';

import { FormFields } from '@epi-forms/helpers';
import { apiToDefaultDateFormat } from '@epi-helpers/dateHelper';
import { dateRangeModes, DATE } from '@epi-constants/downloadReports';
import { setStartDate, setEndDate } from '@epi-helpers/reportsHelper';
import { getApplicationLocale } from '@epi-selectors/application';

export const useTimePeriod = () => {
  const { t } = useTranslation();
  const { setValue, getFieldState, getValues, clearErrors, setError } =
    useFormContext();

  const locale: string = useSelector(getApplicationLocale);

  const [timePeriodDialog, setTimePeriodDialog] = useState(false);

  const startDate = getValues(FormFields.StartDate);
  const endDate = getValues(FormFields.EndDate);
  const selectedId = getValues(FormFields.SelectedID);
  const isError = getFieldState(FormFields.StartDate).error;
  const errorMsg = getFieldState(FormFields.StartDate).error?.message;
  const datePicker =
    startDate && endDate && selectedId === dateRangeModes.datePicker
      ? `${apiToDefaultDateFormat(startDate)} - ${apiToDefaultDateFormat(
          endDate
        )}`
      : t('download_reports.lunch_deduction.time_period.select_dates');

  const options = [
    {
      display: t('download_reports.lunch_deduction.time_period.previous_month'),
      id: dateRangeModes.lastMonth,
      testID: `payroll-report-${dateRangeModes.currentMonth}`
    },
    {
      display: t('download_reports.lunch_deduction.time_period.current_month'),
      id: dateRangeModes.currentMonth,
      testID: `payroll-report-${dateRangeModes.lastMonth}`
    },
    {
      display: datePicker,
      id: dateRangeModes.datePicker,
      testID: `payroll-report-${dateRangeModes.datePicker}`
    }
  ];

  const handleOnChange = (v: ISelectOption) => {
    setValue(FormFields.SelectedID, v.id.toString());
    setValue(FormFields.StartDate, setStartDate(v.id.toString()));
    setValue(FormFields.EndDate, setEndDate(v.id.toString()));
    clearErrors(FormFields.StartDate);

    if (v.id.toString() === dateRangeModes.datePicker) {
      setTimePeriodDialog(true);
    }
  };

  useEffect(() => {
    const from = new Date(startDate);
    if (from && DATE.getTime() > from.getTime()) {
      setError(FormFields.StartDate, {
        type: 'custom',
        message: t('download_reports.lunch_deduction.time_period.error_message')
      });
    }
  }, [locale]);

  return {
    options,
    isError,
    errorMsg,
    handleOnChange,
    timePeriodDialog,
    setTimePeriodDialog,
    selectedId
  };
};

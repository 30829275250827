import React, { useState, useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { SelectComponent as Select, TitleText, Box } from 'edenred-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { dateRangeModes } from '@epi-constants/downloadReports';
import { apiToDefaultDateFormat } from '@epi-helpers/dateHelper';
import { getApplicationLocale } from '@epi-selectors/application';

import { ReportsTabContext } from '../ReportsTabContext';
import { DateRangeDialog } from '../DateRangeDialog';

export const DateRangeSection = ({ dateRangeMode }) => {
  const { t } = useTranslation();
  const { gray50, gray85, sizeExtraSmall, warningColor } = useTheme();
  const { reportsTabState, reportsTabActions } = useContext(ReportsTabContext);
  const [daterangeDialog, setDaterangeDialog] = useState(true);
  const locale = useSelector(getApplicationLocale);
  const datepickerDropdownText =
    reportsTabState.pickerStartDate && reportsTabState.pickerEndDate
      ? `${apiToDefaultDateFormat(
          reportsTabState.pickerStartDate
        )} - ${apiToDefaultDateFormat(reportsTabState.pickerEndDate)}`
      : t('containers.reports.date_range.select_from_picker');

  const options = [
    {
      display: t('containers.reports.date_range.current_month'),
      id: dateRangeModes.currentMonth
    },
    {
      display: t('containers.reports.date_range.previous_month'),
      id: dateRangeModes.lastMonth
    },
    {
      display: datepickerDropdownText,
      id: dateRangeModes.datePicker
    }
  ];

  useEffect(() => {
    reportsTabActions.setErrorMessage(t('containers.reports.date_range.error'));
  }, [locale]);

  return (
    <Box mt={5} mb={3} color={gray85}>
      <TitleText
        fontSize={sizeExtraSmall}
        fontWeight={400}
        color={gray50}
        noMargin
      >
        {t('containers.reports.date_range.title')}
      </TitleText>
      <Box ml={-1}>
        <Select
          id="report-date-range-select"
          selectedId={dateRangeMode}
          options={options}
          onChange={v => {
            reportsTabActions.setDateRangeMode(v.id);
            if (v.id === dateRangeModes.datePicker) {
              setDaterangeDialog(true);
            }
          }}
        />
        {reportsTabState.isErrorDate && (
          <Box pl={1} color={warningColor} fontSize={sizeExtraSmall} height={0}>
            {reportsTabState.errorText}
          </Box>
        )}
        {reportsTabState.dateRangeMode === dateRangeModes.datePicker && (
          <DateRangeDialog
            isOpen={daterangeDialog}
            closeAction={() => setDaterangeDialog(false)}
          />
        )}
      </Box>
    </Box>
  );
};

DateRangeSection.propTypes = {
  dateRangeMode: PropTypes.string.isRequired
};

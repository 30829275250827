import {
  IColumnsPostRequest,
  IPillData
} from '@epi-models/pages/DownloadReports';

export const addKeyToArray = content =>
  content.map((item, index) => ({ ...item, key: index }));

export const arrayIncludesField = (array, field) =>
  array && Array.isArray(array) && array.includes(field);

export const arrayToObject = (array: IPillData[], keyField: string) =>
  array.reduce<IColumnsPostRequest>((obj, item) => {
    obj[item[keyField]] = item.isActive.toString();
    return obj;
  }, {} as IColumnsPostRequest);

export default { addKeyToArray, arrayIncludesField, arrayToObject };

import { TFunction } from 'react-i18next';

export const WAGE_TYPE = 'wageType';
export const DATE = new Date('August 1, 2024');

export const genericErrorMessage = 'messages.error';
export const reportDownloadSuccessMessage =
  'containers.reports.download_success';

export const Benefit = {
  Lounari: 'Lunch',
  Virike: 'Virike'
};

export const TabName = {
  Lunch: 'Lunch',
  Other: 'Other'
};

export const Download = {
  CSV: 'csv',
  XLS: 'xls'
};

export const dateRangeModes = {
  datePicker: 'datePicker',
  currentMonth: 'currentMonth',
  lastMonth: 'lastMonth'
};

export const setPillsData = (t: TFunction<'translation', undefined>) => {
  return [
    {
      id: 0,
      name: t('download_reports.lunch_deduction.list.personal_id'),
      type: 'SSN',
      isActive: true
    },
    {
      id: 1,
      name: t('download_reports.lunch_deduction.list.first_name'),
      type: 'firstName',
      isActive: true
    },
    {
      id: 2,
      name: t('download_reports.lunch_deduction.list.last_name'),
      type: 'lastName',
      isActive: true
    },
    {
      id: 3,
      name: t('download_reports.lunch_deduction.list.benefit'),
      type: 'benefitType',
      isActive: true
    },
    {
      id: 4,
      name: t('download_reports.lunch_deduction.list.sum_of_loads'),
      type: 'sumOfLoads',
      isActive: true
    },
    {
      id: 5,
      name: t('download_reports.lunch_deduction.list.payroll_deduction'),
      type: 'payrollDeduction',
      isActive: true
    },
    {
      id: 6,
      name: t('download_reports.lunch_deduction.list.load_month'),
      type: 'loadMonth',
      isActive: true
    },
    {
      id: 7,
      name: t('download_reports.lunch_deduction.list.request_month'),
      type: 'requestMonth',
      isActive: true
    },
    {
      id: 8,
      name: t('download_reports.lunch_deduction.list.daily_amount'),
      type: 'dailyAmount',
      isActive: true
    },
    {
      id: 9,
      name: t('download_reports.lunch_deduction.list.wage_type'),
      type: 'wageType',
      isActive: false
    }
  ];
};

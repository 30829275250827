export const InvoicingMethod = {
  Email: 'Email',
  EInvoice: 'EInvoice',
  Paper: 'Paper'
};

export const FormArrays = {
  ContactPersons: 'contactPersons'
};

export const FormFields = {
  AcceptTerms: 'acceptTerms',
  AdditionalMessage: 'additionalMessage',
  Address: 'address',
  AddressId: 'addressId',
  Benefit: 'benefit',
  BeneficiariesAmount: 'beneficiariesAmount',
  BusinessId: 'businessId',
  City: 'city',
  Columns: 'columns',
  CompanyName: 'companyName',
  ConfirmPassword: 'confirmPassword',
  CopyCompanyAddress: 'copyCompanyAddress',
  CopyContactPerson: 'copyContactPerson',
  Country: 'country',
  CustomerNumber: 'customerNumber',
  DeliveryMethod: 'deliveryMethod',
  DeliveryAddress: 'deliveryAddress',
  DeliveryCity: 'deliveryCity',
  DeliveryCompanyName: 'deliveryCompanyName',
  DeliveryCountry: 'deliveryCountry',
  DeliveryZipCode: 'deliveryZipCode',
  DownloadAs: 'downloadAs',
  EInvoice: 'eInvoice',
  EInvoiceAddress: 'eInvoiceAddress',
  EmailAddress: 'emailAddress',
  EndDate: 'endDate',
  FirstName: 'firstName',
  FromMonth: 'fromMonth',
  InvoicingContactPerson: 'invoicingContactPerson',
  InvoicingCostCenter: 'invoicingCostCenter',
  InvoicingEmailAddress: 'invoicingEmailAddress',
  InvoicingMethod: 'invoicingMethod',
  JobTitle: 'jobTitle',
  KeepLoggedIn: 'keepLoggedIn',
  LastName: 'lastName',
  NewPassword: 'newPassword',
  MoneyAmount: 'moneyAmount',
  NumberOfEmployees: 'numberOfEmployees',
  Operator: 'operator',
  Password: 'password',
  PaymentMethod: 'paymentMethod',
  PaymentMethodCode: 'paymentMethodCode',
  PhoneNumber: 'phoneNumber',
  RightToSign: 'rightToSign',
  SelectedID: 'selectedId',
  StartDate: 'startDate',
  ToMonth: 'toMonth',
  Username: 'username',
  UsernameOrEmail: 'usernameOrEmail',
  ValidateCompanyInformation: 'validateCompanyInformation',
  VoucherId: 'voucherId',
  VoucherQuantity: 'voucherQuantity',
  VoucherWorth: 'voucherWorth',
  WageType: 'wageType',
  WithoutReferenceNumber: 'withoutReferenceNumber',
  ZipCode: 'zipCode'
};

export function withFormNameContext(context, name) {
  return context ? `${context}.${name}` : name;
}

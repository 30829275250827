import React from 'react';
import { PagePanel, Tabs } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';

import { useDownloadReports, useSetTabs, useReportsTabFooter } from './hooks';

export const DownloadReportsTab = () => {
  const { t } = useTranslation();
  const { form, footer } = useDownloadReports();
  const { otherReportsFooter } = useReportsTabFooter();
  const { tabs, handleOnTabChange, isPayrollTabActive } = useSetTabs();

  return (
    <FormProvider {...form}>
      <PagePanel
        title={t('download_reports.title')}
        footer={isPayrollTabActive ? footer : otherReportsFooter}
      >
        <Tabs tabs={tabs} onTabChange={handleOnTabChange} />
      </PagePanel>
    </FormProvider>
  );
};

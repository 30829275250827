import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Pill,
  PillType,
  Checkbox,
  Box,
  TitleText,
  TextField
} from 'edenred-ui';
import { useTheme } from 'styled-components';
import { useFormContext, useController } from 'react-hook-form';

import { IColumnSection } from '@epi-models/pages/DownloadReports';
import { useColumnSection } from '@epi-pages/DownloadReports/hooks';
import { createRules, FormFields } from '@epi-forms/helpers';
import { maxLength, required } from '@epi-helpers/formHelpers/validators';

const name = FormFields.WageType;

export const ColumnSection = ({ pillsData }: IColumnSection) => {
  const { t } = useTranslation();
  const { sizeMedium, fontSemiBold, gray85, gray10 } = useTheme();
  const { pills, togglePill, wageType } = useColumnSection(pillsData);
  const { control } = useFormContext();
  const validators = [required, maxLength(50)];
  const rules = createRules(validators);
  const { trigger, clearErrors } = useFormContext();

  const {
    field: { value, onChange, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  useEffect(() => {
    if (wageType?.isActive) {
      trigger(name, { shouldFocus: true });
    } else {
      clearErrors(name);
    }
  }, [wageType?.isActive]);

  return (
    <Box mt={5} pb={5} borderBottom={1} borderColor={gray10}>
      <TitleText
        fontSize={sizeMedium}
        fontWeight={fontSemiBold}
        color={gray85}
        noMargin
      >
        {t('download_reports.lunch_deduction.list_title')}
      </TitleText>
      <Box
        display="flex"
        gap={2}
        pt={2.5}
        pb={3}
        flexWrap="wrap"
        data-test="payroll-report-options"
      >
        {pills.map(({ id, isActive, name }) => (
          <Pill
            key={id}
            type={PillType.Basic}
            id={`column-element-payroll-${name}`}
            label={name}
            isActive={isActive}
            action={() => togglePill(id)}
            icon={<Checkbox noPadding checked={isActive} />}
          />
        ))}
      </Box>
      {wageType?.isActive && (
        <Box width={345} pb={2.5}>
          <TextField
            ref={ref}
            data-test="payroll-report-wage-type"
            value={value}
            onChange={onChange}
            label={wageType.name}
            error={!!error}
            helperText={error?.message}
            placeholder={t(
              'download_reports.lunch_deduction.wage_type_placeholder'
            )}
          />
        </Box>
      )}
    </Box>
  );
};

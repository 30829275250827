import React from 'react';

import { ReportsTabProvider } from './components/ReportsTabContext';
import { DownloadReportsTab } from './DownloadReportsTab';

export const DownloadReports = () => {
  return (
    <ReportsTabProvider>
      <DownloadReportsTab />
    </ReportsTabProvider>
  );
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import { RadioComponent } from 'edenred-ui';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { ReportsTabContext } from '../ReportsTabContext';

const DownloadAsStyles = styled.div`
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;

  .title {
    width: 150px;
    position: relative;
    bottom: 5px;
  }
`;

export const DownloadAsSection = ({ downloadAs }) => {
  const { reportsTabActions } = useContext(ReportsTabContext);

  return (
    <DownloadAsStyles>
      <div className="title">{i18n.t('containers.reports.download_as')}</div>
      <RadioComponent
        id="download-as-csv-radio"
        checked={downloadAs === 'csv'}
        value="CSV"
        onChange={() => reportsTabActions.setDownloadAs('csv')}
      >
        CSV
      </RadioComponent>
      <RadioComponent
        id="download-as-xls-radio"
        checked={downloadAs === 'xlsx'}
        value="XLS"
        onChange={() => reportsTabActions.setDownloadAs('xlsx')}
      >
        XLS
      </RadioComponent>
    </DownloadAsStyles>
  );
};

DownloadAsSection.propTypes = {
  downloadAs: PropTypes.string.isRequired
};

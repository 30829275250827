import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { CloseIcon, Pill, PillType, PlusIcon } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { ReportsTabContext } from '../ReportsTabContext';

const dataScopePills = [
  { group: 'employeeData', label: 'Employee data' },
  { group: 'benefits', label: 'Benefits' },
  { group: 'loads', label: 'Loads' }
];

const DataScopeSectionStyle = styled.div`
  padding-top: 48px;
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 40px;
  border-bottom: 1px solid lightgrey;

  .title {
    color: #252525;
    font-size: 16px;
    font-weight: 600;
  }

  .data-scope-pills {
    display: flex;
    flex-flow: row wrap;
    padding-top: 15px;
    gap: 1rem;
  }
`;

export const DataScopeSection = ({ dataScope }) => {
  const { reportsTabActions } = useContext(ReportsTabContext);
  const { t } = useTranslation();

  return (
    <DataScopeSectionStyle>
      <div className="title">
        {i18n.t('containers.reports.data_scope.title')}
      </div>
      <div className="data-scope-pills">
        {dataScopePills.map(p => {
          return (
            <Pill
              key={p.group}
              id={`data-scope-${p.group}`}
              type={PillType.Large}
              label={t(`containers.reports.data_scope.${p.group}`)}
              isActive={dataScope[p.group]}
              action={() => {
                reportsTabActions.setDataScope(p.group);
              }}
              icon={dataScope[p.group] ? <CloseIcon /> : <PlusIcon />}
            />
          );
        })}
      </div>
    </DataScopeSectionStyle>
  );
};

DataScopeSection.propTypes = {
  dataScope: PropTypes.object.isRequired
};

import React from 'react';
import { Box, SelectComponent as Select, TitleText } from 'edenred-ui';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useTimePeriod } from '@epi-pages/DownloadReports/hooks';

import { TimePeriodDialog } from '../TimePeriodDialog';

export const TimePeriod = () => {
  const { gray50, gray85, sizeExtraSmall, warningColor } = useTheme();
  const { t } = useTranslation();
  const {
    options,
    isError,
    errorMsg,
    handleOnChange,
    timePeriodDialog,
    setTimePeriodDialog,
    selectedId
  } = useTimePeriod();

  return (
    <Box mt={5} mb={3} color={gray85}>
      <TitleText
        fontSize={sizeExtraSmall}
        fontWeight={400}
        color={gray50}
        noMargin
      >
        {t('download_reports.lunch_deduction.time_period.title')}
      </TitleText>
      <Box ml={-1}>
        <Select
          label=""
          id="report-date-range-select"
          selectedId={selectedId}
          options={options}
          onChange={handleOnChange}
        />
        {isError && (
          <Box
            pl={1}
            color={warningColor}
            fontSize={sizeExtraSmall}
            height={0}
            data-test="payroll-report-time-period-error-label"
          >
            {errorMsg}
          </Box>
        )}
        {timePeriodDialog && (
          <TimePeriodDialog
            isOpen={timePeriodDialog}
            closeAction={() => setTimePeriodDialog(false)}
          />
        )}
      </Box>
    </Box>
  );
};

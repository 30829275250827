import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import PropTypes from 'prop-types';

import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment';
import { isAfter, isBefore, addDays, subDays } from 'date-fns';
import { DateRangePickerStyles } from './DateRangePicker.styles';
import { datePickerDefaultFormat } from '../../constants/dateFormats';

import 'moment/locale/en-gb';
import 'moment/locale/fi';

const DateRangePicker = ({
  from,
  setFrom,
  to,
  setTo,
  validateFrom,
  validateTo,
  disableFutureDates,
  disabledCustomDates
}) => {
  const { t, language } = useTranslation();
  const today = new Date();
  const fromElement = useRef(null);
  const toElement = useRef(null);
  const handleFromChange = fromValue => {
    if (fromValue) {
      const fromValueIsValid =
        validateFrom === null ? true : validateFrom(fromValue);
      if (fromValueIsValid) {
        setFrom(fromValue);
        if (isAfter(fromValue, to)) {
          const newToDate = addDays(fromValue, 1);
          if (!isAfter(newToDate, today)) {
            setTo(addDays(fromValue, 1));
          } else {
            setTo(fromValue);
          }
        }
      }
    }
  };

  const handleToChange = toValue => {
    const toValueIsValid = validateTo === null ? true : validateTo(toValue);
    if (toValueIsValid) {
      setTo(toValue);
      if (isBefore(toValue, from)) {
        const newFromDate = subDays(toValue, 1);
        setFrom(newFromDate);
      }
    }
  };

  useEffect(() => {
    if (fromElement && fromElement.current) {
      fromElement.current.getInput().focus();
    }
  }, []);
  const modifiers = { start: from, end: to };
  const disabledDays =
    disabledCustomDates || (disableFutureDates ? { after: today } : {});

  return (
    <DateRangePickerStyles>
      <div className="InputFromTo">
        <div className="input-label-group" id="date-from">
          <label>{t('components.date_range_dialog.from')}</label>
          <DayPickerInput
            ref={fromElement}
            value={from}
            format={datePickerDefaultFormat}
            placeholder={datePickerDefaultFormat}
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays,
              toMonth: to,
              modifiers,
              numberOfMonths: 1,
              keepFocus: true,
              onDayClick: () => toElement.current.getInput().focus(),
              localeUtils: MomentLocaleUtils,
              locale: language
            }}
            onDayChange={handleFromChange}
            showOverlay
            showOutsideDays
          />
        </div>
        <div className="InputFromTo-to input-label-group" id="date-to">
          <label>{t('components.date_range_dialog.to')}</label>
          <DayPickerInput
            ref={toElement}
            value={to}
            format={datePickerDefaultFormat}
            placeholder={datePickerDefaultFormat}
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays,
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 1,
              keepFocus: true,
              localeUtils: MomentLocaleUtils,
              locale: language
            }}
            onDayChange={handleToChange}
            showOverlay={false}
            hideOnDayClick={false}
            showOutsideDays
          />
        </div>
      </div>
    </DateRangePickerStyles>
  );
};

DateRangePicker.propTypes = {
  from: PropTypes.instanceOf(Date),
  setFrom: PropTypes.func.isRequired,
  to: PropTypes.instanceOf(Date),
  setTo: PropTypes.func.isRequired,
  validateFrom: PropTypes.func,
  validateTo: PropTypes.func,
  disableFutureDates: PropTypes.bool,
  disabledCustomDates: PropTypes.object
};

DateRangePicker.defaultProps = {
  from: null,
  to: null,
  validateFrom: null,
  validateTo: null,
  disableFutureDates: false
};

export default DateRangePicker;

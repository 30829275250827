import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { setPillsData } from '@epi-constants/downloadReports';

import { ColumnSection } from '../ColumnSection';
import { InfoBar } from '../InfoBar';
import { BenefitReportsGroup } from '../BenefitReportsGroup';
import { DownloadAs } from '../DownloadAs';
import { TimePeriod } from '../TimePeriod';

export const LunchDeduction = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  const pillsData = getValues().columns || setPillsData(t);

  return (
    <>
      <InfoBar
        text={t('download_reports.lunch_deduction.info_bar_text')}
        href={t('download_reports.lunch_deduction.info_bar_link')}
      />
      <BenefitReportsGroup />
      <ColumnSection pillsData={pillsData} />
      <TimePeriod />
      <DownloadAs />
    </>
  );
};

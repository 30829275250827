import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Pill, PillType } from 'edenred-ui';
import i18n from 'i18next';
import { nanoid } from 'nanoid';
import { ReportsTabContext } from '../ReportsTabContext';
import { DataColumnsSectionStyle } from './DataColumnsSection.styles';

export const DataColumnsSection = ({
  dataScope,
  dataColumnPills,
  activeBenefits = []
}) => {
  const { reportsTabActions } = useContext(ReportsTabContext);

  const benefitTypeCheckboxes = activeBenefits && (
    <div className="benefit-types">
      <div className="benefit-types__title">
        {i18n.t('containers.reports.benefit_type.title')}
      </div>
      {activeBenefits.map(benefit => (
        <div className="benefit-types__checkboxes" key={benefit.name}>
          <FormControlLabel
            label={i18n.t(
              `containers.reports.benefit_type.${benefit.name.toLowerCase()}`
            )}
            sx={{
              '& .MuiCheckbox-root svg.MuiSvgIcon-root': {
                marginBottom: 0
              },
              '& span.MuiCheckbox-root.MuiButtonBase-root.MuiCheckbox-sizeMedium':
                {
                  margin: '0 9px'
                }
            }}
            control={
              <Checkbox
                noPadding
                id={`data-columns-checkbox-${benefit.name}`}
                checked={benefit.checked}
                onChange={() => {
                  reportsTabActions.toggleBenefitType(benefit.name);
                }}
              />
            }
          />
        </div>
      ))}
    </div>
  );

  const showBenefitTypeCheckboxes = activeBenefits && activeBenefits.length > 1;

  return (
    <DataColumnsSectionStyle
      showBenefitTypeCheckboxes={showBenefitTypeCheckboxes}
    >
      <div className="column-pills">
        {dataColumnPills.map(p => {
          return dataScope[p.group] ? (
            <Pill
              key={nanoid()}
              type={PillType.Basic}
              id={`data-columns-${p.id}`}
              label={i18n.t(`containers.reports.columns.${p.id}`)}
              isActive={p.active}
              action={() => reportsTabActions.setColumns(p.id)}
              icon={<Checkbox noPadding checked={p.active} />}
            />
          ) : null;
        })}
      </div>
      {benefitTypeCheckboxes}
    </DataColumnsSectionStyle>
  );
};

DataColumnsSection.propTypes = {
  dataScope: PropTypes.object.isRequired,
  dataColumnPills: PropTypes.array.isRequired,
  activeBenefits: PropTypes.array.isRequired
};
